@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/tex-gyre-heros");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

body {
  background-color: #212121;
  font-family: "TeXGyreHerosCondensed", sans-serif;
  font-family: "TeXGyreHeros", sans-serif;
}

.hero-section {
  background-image: url("/public/hero-section.jpg");
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  object-position: bottom;
  background-position: 0px 0px;
  background-attachment: fixed;
}

.hero-section-accent {
  background-image: url("/public/accent-bg-hero.png");
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  object-position: right;
  background-position: 0px 65vh;
  background-attachment: fixed;
}

@media (max-width: 1024px) {
  .hero-section {
    background-position: 0px 0px;
    background-size: cover;
    background-attachment: scroll;
  }

  .hero-section-accent {
    background-position: right 64vh;
    background-size: 1200px 400px;
  }
}

.footer-accent-bg {
  background-image: url("/public/images/accent-bg-footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center top 400px; */
  @apply bg-[center_top_400px];
}

.container-section {
  @apply m-auto py-12 lg:py-[80px];
}

.color-white {
  color: #fcfcfd;
}

.bg-gradient-landing {
  background: linear-gradient(
      0deg,
      rgba(44, 69, 67, 0.6) -49.52%,
      rgba(127, 197, 192, 0) 33.16%
    ),
    #212121;
}

.typography-p {
  @apply opacity-80 leading-[150%] text-base md:text-xl font-normal color-white;
}

.key-feature-box {
  background-image: linear-gradient(
    rgba(128, 128, 128, 0.155),
    rgba(128, 128, 128, 0.101)
  );
  @apply w-full flex justify-center items-start px-6 md:pt-14 pt-12 pb-6 rounded-3xl min-h-[120px];
}

.key-feature-icon {
  background-color: rgba(58, 146, 145, 0.4);
  top: -25%;
  left: 50%;
  transform: translate(-50%, -25%);
  @apply rounded-[19px] lg:rounded-3xl mx-auto -mb-4 p-4 lg:p-6;
}

.use-case-icon {
  background-color: rgba(58, 146, 145, 0.4);
  @apply rounded-[16px] mx-auto -mb-6 p-4 lg:p-4;
}

.use-case-box {
  background-image: linear-gradient(
    rgba(128, 128, 128, 0.155),
    rgba(128, 128, 128, 0.101)
  );
  @apply w-full xl:min-h-[168px] flex justify-center items-start px-6 lg:pt-12 pt-10 pb-6 rounded-3xl;
}

.hero-title {
  background: -webkit-linear-gradient(
    280deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 30%,
    rgba(58, 146, 144, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-title {
  background: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(58, 146, 144, 1) 60%,
    rgba(255, 255, 255, 1) 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-input {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-gray-400, #999);
  background: var(--neutral-gray-600, #666);
  max-height: 47px;
  @apply focus:outline-none;
}

.form-input-textarea {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-gray-400, #999);
  background: var(--neutral-gray-600, #666);
  @apply focus:outline-none;
}

.accordion-trigger {
  background-image: linear-gradient(
    rgba(128, 128, 128, 0.155),
    rgba(128, 128, 128, 0.101)
  );
}

.button-primary {
  background: var(--primary-normal, #3a9290);
  color: #fcfcfd;
  @apply rounded-lg px-[18px] py-[10px] lg:px-5 lg:py-3 flex justify-center items-center gap-2 text-sm lg:text-base font-bold leading-6 hover:bg-[#2D7374] transition-all duration-200;
}

.header {
  background: var(--Black, #212121);
  z-index: 20;
}

.footer {
  background: var(--secondary-darker, #424242);
}

.footer-copyright {
  color: rgba(252, 252, 253, 0.8);
}

.button-get-started {
  border-radius: 8px;
  border: 1px solid var(--White, #fcfcfd);
  @apply hover:text-[#3A9290] hover:border-[#3A9290];
}

.solution-icon .injected-svg {
  @apply w-9 h-9 md:w-12 md:h-12;
}

.injected-svg[data-src="/icons/check_icon.svg"] {
  @apply w-6 h-6 md:w-8 md:h-8;
}

.cta-icon-result .injected-svg {
  @apply w-5 h-5;
}
